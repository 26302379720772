import React from "react"
import { graphql } from "gatsby"

import { withPreview } from "gatsby-source-prismic"

import { isBrowser, PublicRoute } from "@lesmills/gatsby-theme-common"

import FederatedVerification from "../../components/FederatedVerification"

type Props = {|
  location: Object,
  pageContext: {
    lang: string,
  },
  data: Object,
|}

const FederatedVerificationPage = (props: Props) => {
  const { pageContext, data } = props || {}
  const lang = pageContext.lang
  const federatedVerificationData = data.prismicFederatedVerificationPage.data
  const layoutData = data.prismicLayout && data.prismicLayout.data

  const renderFederatedVerificationPage = () => {
    return (
      <>
        {isBrowser && (
          <FederatedVerification
            data={federatedVerificationData}
            layoutData={layoutData}
            location={props.location}
            lang={lang}
          />
        )}
      </>
    )
  }

  return (
    <PublicRoute
      component={renderFederatedVerificationPage}
      {...props}
      lang={lang}
    />
  )
}

export const query = graphql`
  query FederatedVerificationPage($lang: String) {
    prismicFederatedVerificationPage(lang: { eq: $lang }) {
      data {
        federated_verification_error {
          text
          type
        }
        provider_identity_error {
          text
          type
        }
      }
    }
    prismicLayout(lang: { eq: $lang }) {
      data {
        les_mills_plus_logo_black {
          alt
          url
        }
      }
    }
  }
`
export default withPreview(FederatedVerificationPage)
