// @flow
import React, { useEffect, useState } from "react"

// AWS Amplify
import Amplify, { Auth } from "aws-amplify"

import {
  setSessionStorage,
  getSessionStorage,
  awsconfig,
  isBrowser,
  Notification,
  getParamsFromURL,
  RESELLER_ACCESS_ID,
  ROUTES,
  redirectTo,
} from "@lesmills/gatsby-theme-common"
import {
  authStorage,
  sessionReceiverURL,
} from "@lesmills/gatsby-theme-common/src/services/amplifyStorage"

const { GATSBY_RT_26_07_2022_SYNC_SESSION } = process.env

const client = () =>
  Amplify.configure({
    ...awsconfig,
    ...(GATSBY_RT_26_07_2022_SYNC_SESSION === "true" && {
      Auth: {
        storage: authStorage,
      },
    }),
    oauth: {
      domain: process.env.GATSBY_AWS_OAUTH_DOMAIN,
      scope: ["email", "openid", "profile"],
      redirectSignIn: process.env.GATSBY_AWS_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.GATSBY_AWS_OAUTH_REDIRECT_SIGN_OUT,
      responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS",
  })

type Props = {|
  data: Object,
  layoutData: Object,
  lang: string,
|}

const FederatedVerification = ({ data, layoutData, lang }: Props) => {
  const [syncSessionReady, setSyncSessionReady] = useState(
    GATSBY_RT_26_07_2022_SYNC_SESSION === "true" ? false : true
  )
  const [error, setError] = useState("")
  const { federated_verification_error, provider_identity_error } = data
  const { les_mills_plus_logo_black = {} } = layoutData || {}

  const resellerAccessId = getParamsFromURL("reseller")
  if (resellerAccessId) {
    setSessionStorage(RESELLER_ACCESS_ID, resellerAccessId)
  }

  // Init AWS Amplify
  useEffect(() => {
    if (!syncSessionReady && GATSBY_RT_26_07_2022_SYNC_SESSION === "true") {
      return
    }

    client()
    Auth.currentAuthenticatedUser({
      bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(res => {
        const identities = res.signInUserSession
          ? res.signInUserSession.idToken.payload.identities
          : {}
        if (identities.length && identities[0].providerType === "OIDC") {
          redirectTo(ROUTES(lang).FEDERATED_SIGNUP)
        }
      })
      .catch((e) => {
        const accessId =
          resellerAccessId || getSessionStorage(RESELLER_ACCESS_ID)

        const providerIdentity = accessId || (accessId && accessId.length > 0)

        if (providerIdentity) {
          Auth.federatedSignIn({ customProvider: providerIdentity }).catch(
            () => {
              setError(federated_verification_error)
            }
          )
        }
        if (e !== 'The user is not authenticated') {
          setError(provider_identity_error)
        }
      })
  }, [syncSessionReady])

  return (
    isBrowser && (
      <>
        <div className="flex flex-col justify-center items-center w-full">
          <div
            className={`w-full flex md:flex-row flex-col relative justify-center mt-25 md:mt-60`}
          >
            <img
              src={les_mills_plus_logo_black.url}
              className="w-logo-lmod-small h-logo-lmod-small md:m-auto md:absolute md:left-0 mb-10 ml-20 md:ml-30"
              alt="lesmills logo"
              data-cy="lesmills-logo"
            />
          </div>
          {!!error && (
            <Notification
              type="error"
              message={error[0].text}
              classNames={{
                wrapper: "w-80/100 justify-center mx-auto mt-30 ",
              }}
            />
          )}
        </div>

        {GATSBY_RT_26_07_2022_SYNC_SESSION === "true" && sessionReceiverURL && (
          <iframe
            title="session_receiver"
            id="session_receiver"
            src={sessionReceiverURL}
            onLoad={() => setSyncSessionReady(true)}
            width="0"
            height="0"
          />
        )}
      </>
    )
  )
}

export default FederatedVerification
